import { useQuery } from '@apollo/client';
import { FormItem, Select } from 'Components';
import { FormItemProps } from 'Components/Form/FormItem/FormItem';
import { SelectProps } from 'Components/Form/Select/Select';
import { useTranslation } from 'react-i18next';
import { GET_USER_ROLES } from 'Services/Api/Masterdata/Queries';
import { GetUserRolesResponse } from 'Services/Api/Masterdata/Types';

type UserRoleFieldProps = Pick<FormItemProps, 'className' | 'label'> &
	Pick<SelectProps, 'onChange' | 'disabled' | 'value'> & {
		isProfile?: boolean;
		error?: string;
		blockHelp?: boolean;
		required?: boolean;
	};
function UserRoleField({
	isProfile,
	error,
	label,
	className,
	blockHelp,
	required,
	...selectProps
}: UserRoleFieldProps) {
	const { t } = useTranslation();
	const result = useQuery<GetUserRolesResponse>(GET_USER_ROLES, {
		fetchPolicy: 'cache-first'
	});
	const loading = !result.called || result.loading;
	return (
		<FormItem
			className={className}
			label={label === false ? null : label ?? t('user.lmsRole.label')}
			validateStatus={(error && 'error') || undefined}
			help={blockHelp ? undefined : error}
			required={required}
		>
			<Select.ScrollHandled
				{...selectProps}
				loading={loading}
				disabled={isProfile || loading || selectProps.disabled}
				optionFilterProp="children"
				showSearch
			>
				{result.data?.getUserRolesList?.map((userRole) => {
					return (
						<Select.Option key={userRole.id} value={userRole.id}>
							{userRole.role}
						</Select.Option>
					);
				})}
			</Select.ScrollHandled>
		</FormItem>
	);
}

export default UserRoleField;
