import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Form, Formik, FormikHelpers } from 'formik';
import { Alert, Notification, Button, Fx, Input } from 'Components';
import {
	COGNITO_ERROR_MSG_TO_DESCRIPTION_MAP,
	COGNITO_ERROR_MSG
} from 'app-config';

import { useStores } from 'Hooks/useStore';
import {
	SignInRequest,
	CognitoResponseError,
	AuthUser
} from 'Services/Api/Auth/Types';
import { getAppRoutes } from 'Pages/App/App.constants';
import ChangePasswordForm, {
	ChangePasswordFormProps,
	SetShouldMoveToTermsAndConditions
} from './Steps/ChangePasswordForm';
import { AuthState, PhoneNumber } from '../Auth.types';
import { firstLoginScreenSchema, loginSchema } from './schema';
import AcceptTermsAndConditions from './Steps/AcceptTermsAndConditions';

type LoginValues = SignInRequest;

type FirstLoginValues = SignInRequest & PhoneNumber;

type Values = LoginValues | FirstLoginValues;

export interface LoginProps {
	firstTimeSignIn?: boolean;
}
type CognitoUser = AuthUser;

export interface LoginFormProps
	extends LoginProps,
		SetShouldMoveToTermsAndConditions {
	setCognitoUser(user: CognitoUser | null): void;
}
export interface LoginFormWrapProps
	extends LoginFormProps,
		ChangePasswordFormProps {
	shouldMoveToTermsAndConditions: boolean;
}

const LoginForm = observer(
	({
		firstTimeSignIn,
		setCognitoUser,
		setShouldMoveToTermsAndConditions
	}: LoginFormProps) => {
		const { authStore } = useStores();
		const history = useHistory<AuthState>();

		const { t } = useTranslation();

		const forgotPassword = useCallback(() => {
			history.push(getAppRoutes().RESET_PASSWORD);
		}, [history]);

		useEffect(() => {
			if (authStore.currentUser?.isConsentGiven === false) {
				setCognitoUser((authStore.currentUser as unknown) as AuthUser);
				setShouldMoveToTermsAndConditions(true);
			}
			// do not include `setCognitoUser, setShouldMoveToTermsAndConditions`
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [authStore.currentUser?.isConsentGiven]);

		const onSubmit = async (
			values: Values,
			helpers: FormikHelpers<Values>
		) => {
			helpers.setStatus('');
			try {
				const user = await authStore.signIn(values);

				if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
					setCognitoUser(user);
				} else {
					const url =
						history.location.state?.returnUrl ??
						getAppRoutes().HOME;
					const historyState = {
						userLoggedIn: true
					};
					history.push(url, historyState);
				}
			} catch (e) {
				const error = e as CognitoResponseError;
				helpers.setSubmitting(false);

				if (
					error.message ===
					COGNITO_ERROR_MSG.TEMPORARY_PASSWORD_EXPIRED
				) {
					history.push(getAppRoutes().RESET_TEMPORARY_PASSWORD, {
						cognitoLoginTemporaryPasswordExpired: values.login
					});
					return;
				}

				const cognitoError =
					COGNITO_ERROR_MSG_TO_DESCRIPTION_MAP[error.message];

				if (cognitoError) {
					helpers.setStatus(t(cognitoError));
					return;
				}

				switch (error.code) {
					case 'NotAuthorizedException':
					case 'UserNotFoundException':
						helpers.setStatus(t('auth.login.errors.notAuthorized'));
						break;
					default:
						Notification.error({
							description: t('errorCodes.genericErrorMessage')
						});
						break;
				}
			}
		};

		const initialValues: Values = {
			login: '',
			password: ''
		};

		const schema = firstTimeSignIn ? firstLoginScreenSchema : loginSchema;

		return (
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				validationSchema={schema}
			>
				{({ isValid, dirty, isSubmitting, status, setFieldValue }) => (
					<>
						<h1>{t('auth.login.title')}</h1>
						{status && (
							<Alert
								showIcon
								message=""
								description={status}
								type="error"
							/>
						)}
						<Form>
							<Input.FormikField
								name="login"
								label={t('auth.login.inputs.login')}
								autoComplete="off"
								required={firstTimeSignIn}
								onBlur={({ target }) => {
									setFieldValue(
										'login',
										target.value.trim(),
										true
									);
								}}
								onChange={({ target }) => {
									setFieldValue(
										'login',
										target.value.trim(),
										true
									);
								}}
								onPaste={({ target }) => {
									setTimeout(() => {
										setFieldValue(
											'login',
											// @ts-ignore
											target.value.trim(),
											true
										);
									}, 0);
								}}
							/>
							<Input.PasswordFormikField
								name="password"
								required={firstTimeSignIn}
								label={t(
									`auth.login.inputs.${
										firstTimeSignIn
											? 'temporaryPassword'
											: 'password'
									}`
								)}
							/>
							{!firstTimeSignIn && (
								<Fx justify="end">
									<Button
										type="text"
										link
										thin
										onClick={forgotPassword}
									>
										{t('auth.login.forgotYourPassword')}
									</Button>
								</Fx>
							)}

							<div>
								<Button
									htmlType="submit"
									type="primary"
									shape="round"
									fullWidth
									disabled={!(isValid && dirty)}
									loading={isSubmitting}
								>
									{t('auth.login.submitText')}
								</Button>
							</div>
						</Form>
					</>
				)}
			</Formik>
		);
	}
);

export function LoginFormWrap({
	firstTimeSignIn,
	setCognitoUser,
	cognitoUser,
	shouldMoveToTermsAndConditions,
	setShouldMoveToTermsAndConditions
}: LoginFormWrapProps) {
	if (cognitoUser) {
		if (shouldMoveToTermsAndConditions)
			return (
				<AcceptTermsAndConditions
					setShouldMoveToTermsAndConditions={
						setShouldMoveToTermsAndConditions
					}
					setCognitoUser={setCognitoUser}
				/>
			);

		return (
			<ChangePasswordForm
				cognitoUser={cognitoUser}
				setShouldMoveToTermsAndConditions={
					setShouldMoveToTermsAndConditions
				}
			/>
		);
	}

	return (
		<LoginForm
			firstTimeSignIn={firstTimeSignIn}
			setShouldMoveToTermsAndConditions={
				setShouldMoveToTermsAndConditions
			}
			setCognitoUser={setCognitoUser}
		/>
	);
}

export default LoginFormWrap;
