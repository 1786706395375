import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Fx, Layout, Tabs, Modal } from 'Components';
import { useStores } from 'Hooks/useStore';

import DashboardFilters from './DashboardFilters/DashboardFilters';
import DashboardExport from './DashboardExport/DashboardExport';
import { DashboardContext } from './DashboardContext';

import { DashboardFiltersValues } from './Dashboard.types';
import styles from './Dashboard.module.scss';
import { useDashboardTabs } from './Dashboard.hooks';
import { DashboardTabKey } from 'Services/Api/Dashboard/Types';

import { useModal } from 'Hooks/useModal';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { getAppRoutes } from '../App/App.constants';
import { getLeadPath } from '../App/AppLayout/AppHeader/AppHeader.helpers';

function Dashboard() {
	const { t } = useTranslation();
	const stores = useStores();
	const { tabs } = useDashboardTabs();

	const [filterValues, setFilterValues] = useState<DashboardFiltersValues>(
		{}
	);
	const [hasProperties, setHasProperties] = useState(true);
	const onDashboardFiltersChange = (value: DashboardFiltersValues) => {
		setFilterValues({
			...filterValues,
			...value
		});
	};

	const [activeTab, setActiveTab] = useState(
		tabs.find((tab) => tab.hasPermission)?.key
	);
	
	const { modal, showModal, hideModal } = useModal();
	const [greeting, setGreeting] = useState(0);
	const onClosegreeting = () => {
		hideModal();
		setGreeting(greeting+1);
		localStorage.setItem("greeting","1");
	};
	const greetinglocal = localStorage.getItem("greeting");	

	const {authStore, systemsStore } = useStores();
	const history = useHistory();
	const gousers = () => {
		history.push(getAppRoutes().USERS);
	};
	const { pathname } = useLocation();
	const buttonspushes = localStorage.getItem("initalpull");	

	const listallstores = JSON.stringify(systemsStore);
	const listallstoresarray = JSON.parse(listallstores);
	const awstokenbracket = stores.authStore;
	const listallawstokenbracket = JSON.stringify(awstokenbracket);
	const newtokenlist = JSON.parse(listallawstokenbracket);
	let isuperadm = '';
	const checkrole = JSON.stringify(newtokenlist.currentUser.primarySystemRole.id);
	if(checkrole == '1'){
		isuperadm = '&awsUserIsSuperAdmin=true';
	}
	let leadPath = getLeadPath(pathname);
	localStorage.setItem("APIadmin",isuperadm);
	if(typeof leadPath === 'string'){
		localStorage.setItem("APIpath",leadPath);
	}else{
		localStorage.setItem("APIpath",'');
	}
	const checkurl = (urlgo:string) => {
		if(newtokenlist.authSession.idToken.jwtToken){
			let useurlgo = urlgo;
			if(leadPath == '/'){
				leadPath = '';
			}else{
				useurlgo = useurlgo+'/';
			}
			const usetokennow = JSON.stringify(newtokenlist.authSession.idToken.jwtToken);
			const fullurl = leadPath+'/rlink.php?awsToken='+usetokennow.replace(/['"]+/g,'')+isuperadm+'&page=module'.replace('//','/');
			//console.log('***'+fullurl+'***');
			window.open(urlgo+fullurl);
		}
	}
	
	useEffect(() => {
		return () => {
			stores.clearDashboardStore();
		}
		// eslint-disable-next-line
	}, []);
	
	if(buttonspushes == "0"){
		localStorage.setItem("initalpull","1");
		setTimeout(() => {history.push(getAppRoutes().HOME)},500);
		//console.log('rerendered HOME...')
	}

	if(checkrole != '4'){
		return (
			<DashboardContext.Provider
				value={{
					filters: filterValues,
					setActiveTab,
					hasProperties
				}}
			>
				<Layout>
					<Layout.Heading>
						<Fx align="middle" justify="space-between">
							<h1>{t('dashboard.title')}</h1>
							<DashboardExport />
						</Fx>
					</Layout.Heading>
						<div id="temporarygreeting">
							<div className='divfullwidthcenter'>
								<div className='divtitlecenter'>
									Notice
									<br />&nbsp;<br />
								</div>
								<div className='div75widthcenter'>
									As part of ongoing product improvements, the VENZA System Dashboard will be temporarily deactivated. 
									<br />&nbsp;<br />
									Changing this view does not affect the accuracy or availability of your data.
									<br />&nbsp;<br />
									&bull;&nbsp;&nbsp;To access your reports, click on "Go to PEAK Dashboard". 
									<br />&nbsp;<br />
									&bull;&nbsp;&nbsp;To access user management, click on "Manage User".
									<br />&nbsp;<br />
									We're committed to delivering the best user experience and thank you for your continued patience.
									<br />&nbsp;<br />
									Need help? Visit our <a href="https://help.venzagroup.com" target="_blank">Support Site</a> for step-by-step guides or email <a href="mailto:tickets@venzagroup.com" target="_blank">tickets@venzagroup.com.</a> to open a support ticket.
									<br />&nbsp;<br />
									<br />&nbsp;<br />
								</div>
								<div className="buttoncnthalf">
									{listallstoresarray['systems'].map(function(data:any){
										return (
											
											<div>
												<Button key={data.id} shape="round" type="primary" onClick={() => checkurl(data.url)}>Go to {data.systemName} Dashboard</Button>
												<div>&nbsp;</div>
											</div>
										)
									})}
								</div>
								<div className="buttoncnthalf">
									<Button
										shape="round"
										type="primary"
										onClick={gousers}
									>
										Go to Manage Users
									</Button>
									<div>&nbsp;</div>
								</div>
								<br />&nbsp;<br />
								<br />&nbsp;<br />
							</div>
						</div>
					<Tabs
						destroyInactiveTabPane
						className={styles.tabs}
						tabBarExtraContent={
							<DashboardFilters
								onChange={onDashboardFiltersChange}
								onSetHasProperties={setHasProperties}
							/>
						}
						activeKey={activeTab}
						onChange={(activeTab) =>
							setActiveTab(activeTab as DashboardTabKey)
						}
					>
						{tabs.map(
							({ key, tab, component: Component, hasPermission }) => {
								if (!hasPermission) {
									return null;
								}
								return (
									<Tabs.TabPane key={key} tab={t(tab)}>
										<Component />
									</Tabs.TabPane>
								);
							}
						)}
					</Tabs>
				</Layout>
			{greetinglocal != '0' &&
				<Modal
				visible
				title='Notice'
				cancelButtonVisible={false}
				onOk={onClosegreeting}
				maskClosable={true}
				okText='Continue'
				>
				The VENZA System is currently in its initial release.<br />&nbsp;<br />We&apos;re refining the experience, so you might encounter some temporary glitches, such as issues with data syncing or real-time dashboard updates.<br />&nbsp;<br />Thank you for your patience while we work hard to squash every bug.<br />&nbsp;<br />Need help?<br />&nbsp;<br />Visit our <a href="https://help.venzagroup.com" target="_blank">Support Site</a> for step-by-step guides.<br />&nbsp;<br />To open a support ticket, email <a href="mailto:tickets@venzagroup.com" target="_blank">tickets@venzagroup.com.</a>
				</Modal>
			}
			</DashboardContext.Provider>
		);
	}else{
		return (
			<DashboardContext.Provider
				value={{
					filters: filterValues,
					setActiveTab,
					hasProperties
				}}
			>
				<Layout>
					<Layout.Heading>
						<Fx align="middle" justify="space-between">
							<h1>{t('dashboard.title')}</h1>
							<DashboardExport />
						</Fx>
					</Layout.Heading>
						<div id="temporarygreeting">
							
						</div>
					<Tabs
						destroyInactiveTabPane
						className={styles.tabs}
						tabBarExtraContent={
							<DashboardFilters
								onChange={onDashboardFiltersChange}
								onSetHasProperties={setHasProperties}
							/>
						}
						activeKey={activeTab}
						onChange={(activeTab) =>
							setActiveTab(activeTab as DashboardTabKey)
						}
					>
						{tabs.map(
							({ key, tab, component: Component, hasPermission }) => {
								if (!hasPermission) {
									return null;
								}
								return (
									<Tabs.TabPane key={key} tab={t(tab)}>
										<Component />
									</Tabs.TabPane>
								);
							}
						)}
					</Tabs>
				</Layout>
			</DashboardContext.Provider>
		);
	}

}

export default Dashboard;